import { isNotAuthorized, isNotSomeAuthorization } from './util/role.utils';

export default [
  {
    name: 'Visão geral',
    icon: 'nc-icon nc-layout-11',
    path: '/overview',
    disabled: false
  },
  {
    name: 'Suit Journey',
    icon: 'fa-solid fa-map-location-dot',
    disabled: isNotAuthorized('SUIT_JOURNEY_PRE_LAUNCH'),
    path: '/suitjourney/prelaunch'
  },
  {
    name: 'Suit Journey',
    icon: 'fa-solid fa-map-location-dot',
    disabled: isNotAuthorized('SUIT_JOURNEY'),
    path: '/suitjourney/page'
  },
  {
    name: 'Transações',
    icon: 'fas fa-exchange-alt',
    disabled: false,
    children: [
      {
        name: 'TED',
        icon: 'fas fa-arrow-up',
        path: '/transfer/ted',
        disabled: false,
      },
      {
        name: 'Pagamentos de Tít. e Conv.',
        icon: 'fas fa-barcode',
        path: '/payment/pay',
        disabled: false,
      },
      {
        name: 'Transf. entre contas',
        icon: 'fas fa-exchange-alt',
        path: '/transfer/tev',
        disabled: false,
      },
      {
        name: 'Depósito por Boleto',
        icon: 'fas fa-file-invoice-dollar',
        path: '/deposit/boleto',
        disabled: false,
      },
      {
        name: 'Recebimento via PIX',
        icon: 'fas fa-arrow-up',
        path: '/pix/depositpix',
        disabled: false,
      },
      {
        name: 'Transferência via PIX',
        icon: 'fas fa-arrow-up',
        path: '/pix/paymentpix',
        disabled: false,
      },
    ]
  },
  {
    name: 'Relatórios',
    icon: 'fas fa-chart-line',
    disabled: false,
    children: [
      {
        name: 'Extrato',
        icon: 'nc-icon nc-paper',
        path: '/transactions/byperiod',
        disabled: false
      },
      {
        name: 'Pix de Entrada Gateway',
        icon: 'nc-icon nc-paper',
        path: '/transactions/pix/in',
        disabled: false
      },
      {
        name: 'Pix de Saída Gateway',
        icon: 'nc-icon nc-paper',
        path: '/transactions/pix/out',
        disabled: false
      },
      {
        name: 'Webhook',
        icon: 'nc-icon nc-paper',
        path: '/transactions/webhook',
        disabled: isNotAuthorized('WEBHOOK_REPORT')
      },
      {
        name: 'Parceiro Pix',
        path: '/transactions/pixpartner',
        disabled: isNotAuthorized('PIX_PARTNER_REPORT')
      },
      {
        name: 'Informe de Movimentação',
        path: '/transactions/movement',
        disabled: isNotAuthorized('FINANCIAL_TRANSACTION_REPORT')
      }
    ]
  },
  {
    name: 'Gateway / Checkout',
    icon: 'fas fa-hand-holding-usd',
    disabled: false,
    children: [
      {
        name: 'Checkout',
        path: '/gateway/accesscheckout',
        disabled: false,
      },
      {
        name: 'Relatório de Pagamentos',
        path: '/gateway/paymentsreport',
        disabled: false,
      },
      {
        name: 'Gerenciamento de IPs',
        path: '/gateway/ip',
        disabled: isNotAuthorized('GENERATE_API_ACCESS'),
      },
      {
        name: 'Bloqueio Cash-out',
        path: '/gateway/blocked-bank',
        disabled: isNotAuthorized('USER_BLOCK_BANK'),
      }
    ]
  },
  {
    name: 'Suit Card',
    icon: 'fas fa-credit-card',
    disabled: !isNotSomeAuthorization(['CARD_REQUEST', 'CARD_ADD_FUNDS', 'CARD_WITHDRAW_FUNDS', 'CARD_UPDATE', 'CARD_GET', 'CARD_LIST', 'CARD_TRANSACTION_JUSTIFY', 'CARD_JUSTIFICATION_GET', 'COST_CENTER_NEW', 'COST_CENTER_GET', 'COST_CENTER_UPDATE', 'CARD_TRANSACTION_LIST']),
    path: '/maintenance/maintenance',
  },
  {
    name: 'Suit Card',
    icon: 'fas fa-credit-card',
    disabled: isNotSomeAuthorization(['CARD_REQUEST', 'CARD_ADD_FUNDS', 'CARD_WITHDRAW_FUNDS', 'CARD_UPDATE', 'CARD_GET', 'CARD_LIST', 'CARD_TRANSACTION_JUSTIFY', 'CARD_JUSTIFICATION_GET', 'COST_CENTER_NEW', 'COST_CENTER_GET', 'COST_CENTER_UPDATE', 'CARD_TRANSACTION_LIST']),
    children: [
      {
        name: 'Cartão Virtual',
        path: '/credit-card/manage/virtual',
        disabled: false,
      },
      {
        name: 'Cartão Físico',
        // path: '/maintenance/maintenance',
        path: '/credit-card/manage/physical',
        disabled: isNotSomeAuthorization(['CARD_PHYSICAL_REQUEST']),
      },
      {
        name: 'Centro de Custo',
        path: '/cost-center/manage',
        disabled: false,
      },
      {
        name: 'Extrato',
        path: '/credit-card/transactions',
        disabled: false,
      }
    ]
  },
  {
    name: 'Câmbio',
    icon: 'fa-solid fa-money-bill-transfer',
    disabled: isNotSomeAuthorization(['REQUEST_BUY_EXCHANGE']),
    children: [
      {
        name: 'Realizar Câmbio',
        path: '/exchange/requestexchange',
        disabled: false,
      },
      {
        name: 'Extrato',
        path: '/exchange/foreignexchangestatement',
        disabled: false,
      }
    ]
  },
  {
    name: 'Habilitar Aplicativo',
    icon: 'fa-solid fa-mobile-screen-button',
    path: '/user/device',
    disabled: isNotSomeAuthorization(['REGISTER_DEVICE'])
  },
  {
    name: 'Suporte',
    icon: 'fa-solid fa-circle-info',
    path: '/support/support',
    disabled: false
  },
  {
    name: 'Notificações',
    icon: 'fa-regular fa-bell',
    disabled: isNotSomeAuthorization(['MANAGE_NOTIFICATION']),
    path: '/notifications/manage'
  },
  {
    name: 'Colaboradores',
    icon: 'fa-solid fa-users',
    disabled: isNotSomeAuthorization(['MANAGE_COLLABORATORS']),
    children: [
      {
        name: 'Adicionar',
        path: '/collaborators/manage',
        disabled: false
      },
      {
        name: 'Área do Colaborador',
        path: '/collaborators/area',
        disabled: false
      }
    ]
  },
  {
    name: 'Carteira Digital',
    icon: 'fas fa-wallet',
    path: '/maintenance/maintenance/requestmachine',
    disabled: true,
    children: [
      {
        name: 'Wallet',
        path: '/marketplace/wallet',
        disabled: false,
      },
      {
        name: 'Exchange',
        path: '/marketplace/exchange',
        disabled: false
      },
    ]
  },
  {
    name: 'Link de Pagamento',
    icon: 'fas fa-link',
    disabled: isNotSomeAuthorization(['REQUEST_PAYMENT_LINK']),
    children: [
      {
        name: 'Novo Link de Pagamento',
        path: '/paymentlink/requestsale',
        disabled: false,
      },
      {
        name: 'Relatório de Links',
        path: '/paymentlink/financialreport',
        disabled: false,
      },
      {
        name: 'Relatório de Pagamentos',
        path: '/paymentlink/paymentsreport',
        disabled: false,
      }
    ]
  },
  {
    name: 'Máquina de Cartão',
    icon: 'fas fa-calculator',
    path: '/maintenance/maintenance/requestmachine',
    disabled: true, //isNotSomeAuthorization('CARD_TERMINAL_REQUEST', 'CARD_TERMINAL_REQUESTED_LIST', 'CARD_TERMINAL_USER_LIST'),
    children: [
      {
        name: 'Realizar Pedido',
        path: '/card-terminal/request',
        disabled: false,//isNotAuthorized('CARD_TERMINAL_REQUEST')
      },
      {
        name: 'Consultar Meu Plano',
        path: '/card-terminal/consultplan',
        disabled: false
      },
      {
        name: 'Relatório Financeiro',
        path: '/card-terminal/consulttransactions',
        disabled: false
      }
    ]
  },
  {
    name: 'Verificar Conta',
    icon: 'nc-icon nc-single-02',
    path: '/user/profile',
    disabled: localStorage.getItem('docsOk') == 'true'
  },
  {
    name: 'Link de Indicação',
    icon: 'fas fa-hands-helping',
    path: '/recommendation/generaterecommendationcode',
    disabled: isNotSomeAuthorization(['RECOMMENDATION_LINK'])
  },
  {
    name: 'BO. Cadastros',
    icon: 'nc-icon nc-briefcase-24',
    disabled: isNotSomeAuthorization(['BO_LIST_ACCOUNT', 'BO_LIST_STATEMENT_MANAGER']),
    children: [
      {
        name: 'Contas',
        path: '/bo/account/search/legal',
        disabled: isNotAuthorized('BO_LIST_ACCOUNT')
      },
      {
        name: 'Extrato',
        path: '/bo/statement/search',
        disabled: isNotSomeAuthorization(['BO_LIST_STATEMENT', 'BO_LIST_STATEMENT_MANAGER'])
      },
      {
        name: 'Aprovações',
        path: '/bo/account/search/approval',
        disabled: isNotAuthorized('BO_LIST_ACCOUNT_APPROVAL')
      }
    ]
  },
  {
    name: 'BO. Gateway',
    icon: 'fa-solid fa-comments-dollar',
    disabled: isNotSomeAuthorization(['BO_LIST_GATEWAY_IPS' , 'BO_COMMERCIAL' , 'BO_GATEWAY_MANAGE', 'BO_USER_BLOCK']),
    children: [
      {
        name: 'Gerenciar Cadastros',
        path: '/bo/accountgatewayacquirer/search',
        disabled: isNotAuthorized('BO_GATEWAY_MANAGE')
      },
      {
        name: 'Cancelar Pix',
        path: '/bo/gateway/cancelsalepix',
        disabled: isNotAuthorized('BO_GATEWAY_MANAGE')
      },
      {
        name: 'Cadastro de IPs',
        path: '/bo/gateway/manageclientip',
        disabled: isNotSomeAuthorization(['BO_LIST_GATEWAY_IPS' , 'BO_COMMERCIAL'])
      },
      {
        name: 'Consultar por qrCode',
        path: '/bo/gateway/consultpix',
        disabled: isNotAuthorized('BO_COMMERCIAL', 'BO_COMPLIANCE')
      },
      {
        name: 'Bloqueios por Usuário',
        path: '/bo/gateway/manage-client-block',
        disabled: isNotAuthorized('BO_USER_BLOCK')
      }
    ]
  },
  {
    name: 'BO. ROLES',
    icon: 'fas fa-user-lock',
    disabled: isNotSomeAuthorization(['BO_ROLES']),
    children: [
      {
        name: 'Inserir Role',
        path: '/bo/roles/registerrole',
        disabled: isNotAuthorized('INSERT_ROLE')
      },
      {
        name: 'Inserir Pack',
        path: '/bo/roles/registerrolepack',
        disabled: isNotAuthorized('INSERT_ROLE_PACK')
      },
      {
        name: 'Gerenciar Usuários',
        path: '/bo/roles/manageusers',
        disabled: isNotAuthorized('MANAGE_USERS_ROLE')
      },
      {
        name: 'Gerenciar Packs',
        path: '/bo/roles/managepacks',
        disabled: isNotAuthorized('MANAGE_PACKS_ROLE')
      },
      {
        name: 'Gerenciar Roles',
        path: '/bo/roles/manageroles',
        disabled: isNotAuthorized('MANAGE_ROLES')
      }
    ]
  },
  {

    name: 'BO. SUIT CARD',
    icon: 'fa-solid fa-briefcase',
    disabled: isNotSomeAuthorization(['BO_CREDIT_CARD_LIST']),
    path: '/bo/credit-card',
  },
  {
    name: 'BACKOFFICE',
    icon: 'nc-icon nc-atom',
    disabled: isNotSomeAuthorization(['BO_LIST_GLOBAL_OP_LIMITS']),
    children: [
      {
        name: 'Limites Globais',
        path: '/bo/operation-limit/edit',
        disabled: isNotAuthorized('BO_LIST_GLOBAL_OP_LIMITS')
      },
      {
        name: 'Recargas Via TED',
        path: '/bo/credit-ted/manageCreditTed',
        disabled: isNotAuthorized('BO_GET_LIST_CREDIT_TED')
      }
    ]
  },
  {
    name: 'BO. EXCHANGE',
    icon: 'nc-icon nc-atom',
    disabled: true,
    children: [
      {
        name: 'Compras',
        path: '/bo/marketplace/manageBuyBtc',
        disabled: isNotAuthorized('BO_MANAGE_EXCHANGE')
      },
      {
        name: 'Vendas',
        path: '/bo/marketplace/manageSellBtc',
        disabled: isNotAuthorized('BO_MANAGE_EXCHANGE')
      }
    ]
  },
  {
    name: 'BO. MAQ. CARTAO',
    icon: 'fas fa-calculator',
    disabled: isNotSomeAuthorization(['BO_CARD_TERMINALS']),
    children: [
      {
        name: 'Gerenciar Solicitações',
        path: '/bo/cardterminal/managerequest',
        disabled: isNotAuthorized('BO_MANAGE_REQ_CARD_TERMINALS')
      },
      {
        name: 'Inserir Máquina',
        path: '/bo/cardterminal/registercardterminal',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL')
      },
      {
        name: 'Gerenciar Máquinas',
        path: '/bo/cardterminal/managecardterminal',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL')
      },
      {
        name: 'Gerenciar Marcas',
        path: '/bo/cardterminal/managebrands',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL_BRAND')
      },
      {
        name: 'Gerenciar Modelos',
        path: '/bo/cardterminal/managemodels',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL_MODEL')
      },
      {
        name: 'Gerenciar Planos',
        path: '/bo/cardterminal/manageplans',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL_PLANS')
      },
      {
        name: 'Gerenciar Bandeiras',
        path: '/bo/cardterminal/managebanner',
        disabled: isNotAuthorized('BO_UPDATE_CARD_TERMINAL_BANNER')
      }
    ]
  },

  {
    name: 'BO. FINANCEIRO',
    icon: 'fas fa-chart-bar',
    disabled: isNotSomeAuthorization(['BO_FINANCIAL', 'BO_COMMERCIAL_FINANCIAL', 'BO_FINANCIAL_ONLINE']),
    children: [
      {
        name: 'Vendas Online Sintético',
        path: '/bo/financial/online/useronlinereport',
        disabled: isNotSomeAuthorization(['BO_FINANCIAL_ONLINE', 'BO_COMMERCIAL_FINANCIAL'])
      },
      {
        name: 'Vendas Online Analítico',
        path: '/bo/financial/online/salesReport',
        disabled: isNotSomeAuthorization(['BO_COMMERCIAL_FINANCIAL', 'BO_FINANCIAL_ONLINE', 'BO_COMMERCIAL'])
      },
      {
        name: 'Vendas Máq. Usuário',
        path: '/bo/cardterminal/financial/userreport',
        disabled: isNotAuthorized('BO_CARD_TERM_USER_REPORT')
      },
      {
        name: 'Relatório de Lucros',
        path: '/bo/financial/profitreport',
        disabled: isNotAuthorized('BO_PROFIT_REPORT')
      },
      {
        name: 'Recarga Manual de PIX',
        path: '/bo/financial/pixcharge',
        disabled: isNotAuthorized('BO_PIX_CHARGE')
      },
      {
        name: 'Transf. PIX Aarin',
        path: '/bo/financial/pixtransferinternal',
        disabled: isNotAuthorized('BO_PIX_CHARGE')
      }
    ]
  },

  {
    name: 'BO. PAGAMENTOS',
    icon: 'fas fa-money',
    disabled: isNotSomeAuthorization(['BO_DISTRIBUTED_VALUES']),
    children: [
      {
        name: 'Distribuir Máquinas',
        path: '/bo/cardterminal/distributevalues',
        disabled: isNotAuthorized('BO_DISTRIBUTED_VALUES')
      },
      {
        name: 'Distribuir Online',
        path: '/bo/gateway/distributevalues',
        disabled: isNotAuthorized('BO_DISTRIBUTED_VALUES')
      }
    ]
  },

  {
    name: 'BO. Câmbio',
    icon: 'fa-solid fa-comments-dollar',
    disabled: isNotSomeAuthorization(['BO_EXCHANGE']),
    children: [
      {
        name: 'Gerenciar Solicitações',
        path: '/bo/exchange/manageexchange',
        disabled: isNotAuthorized('BO_EXCHANGE')
      }
    ]
  },

  {
    name: 'BO. PARCEIRO',
    icon: 'far fa-handshake',
    disabled: isNotSomeAuthorization(['BO_PARTNER_CARD_TERM_REPORT']),
    children: [
      {
        name: 'Relatório de Vendas',
        path: '/bo/partner/cardterminalreport',
        disabled: isNotAuthorized('BO_PARTNER_CARD_TERM_REPORT')
      }
    ]
  },

  {
    name: 'BO. COMERCIAL',
    icon: 'fa-solid fa-briefcase',
    disabled: isNotSomeAuthorization(['BO_COMMERCIAL']),
    children: [
      {
        name: 'Gerenciar Vendedores',
        path: '/bo/commercial/manage',
        disabled: isNotAuthorized('BO_COMMERCIAL_SALES_PERSON')
      },
      {
        name: 'Gerenciar Gestores',
        path: '/bo/commercial/manager/manage',
        disabled: isNotAuthorized('BO_COMMERCIAL_ADD_MANAGER')
      },
      {
        name: 'Gerenciar Regiões',
        path: '/bo/commercial/region/manage',
        disabled: isNotAuthorized('BO_COMMERCIAL_ADD_REGION')
      },
      {
        name: 'Gerenciar Níveis',
        path: '/bo/commercial/level/manage',
        disabled: isNotAuthorized('BO_COMMERCIAL_ADD_LEVEL')
      },
      {
        name: 'Gerenciar Carteira',
        path: '/bo/commercial/comercialportifolio',
        disabled: isNotAuthorized('BO_COMMERCIAL_SALES_PERSON')
      },
      {
        name: 'Dashboard',
        path: '/bo/commercial/dashboard',
        disabled: isNotAuthorized('BO_COMMERCIAL_EDIT_CLIENT')
      },
      {
        name: 'Relatório de Pagamentos',
        path: '/bo/commercial/commissions',
        disabled: isNotAuthorized('BO_COMMERCIAL_COMMISSIONS')
      },
      {
        name: 'Relatório de Vendas Analítico',
        path: '/bo/commercial/salesreport',
        disabled: isNotAuthorized('BO_COMMERCIAL_SALES_REPORT')
      },
      {
        name: 'Relatório de Vendas Sintético',
        path: '/bo/commercial/useronlinereport',
        disabled: isNotAuthorized('BO_COMMERCIAL_SALES_REPORT')
      }
    ]
  },

  {
    name: 'BO. Suit Journey',
    icon: 'fa-solid fa-map-location-dot',
    disabled: isNotSomeAuthorization(['BO_SUIT_JOURNEY']),
    children: [
      {
        name: 'Gerenciar Placas',
        path: '/bo/suitjourney/manage/plate',
        disabled: isNotAuthorized('BO_SUIT_JOURNEY')
      },
      {
        name: 'Gerenciar Estoque',
        path: '/bo/suitjourney/manage/plate-stock',
        disabled: isNotSomeAuthorization(['BO_SUIT_JOURNEY', 'BO_SUIT_JOURNEY_PLATE_ACTIONS'])
      },
      {
        name: 'Notas Fiscais',
        path: '/bo/suitjourney/manage/plate-fiscal-note',
        disabled: isNotSomeAuthorization(['BO_SUIT_JOURNEY', 'BO_SUIT_JOURNEY_PLATE_ACTIONS'])
      },
      {
        name: 'Gerenciar Solicitações',
        path: '/bo/suitjourney/manage/request',
        disabled: isNotAuthorized('BO_SUIT_JOURNEY')
      },
    ]
  },
  {
    name: 'BO. COMPLIANCE',
    icon: 'fa-solid fa-file-circle-check',
    disabled: isNotSomeAuthorization(['BO_COMPLIANCE']),
    children: [
      {
        name: 'Usuários Sinalizados',
        path: '/bo/compliance/flagged-users',
        disabled: isNotAuthorized('BO_COMPLIANCE')
      },
      {
        name: 'Planilhas MED',
        path: '/bo/compliance/spreadsheet',
        disabled: isNotAuthorized('BO_COMPLIANCE')
      },
      {
        name: 'Devoluções MED',
        path: '/bo/compliance/med',
        disabled: isNotAuthorized('BO_COMPLIANCE')
      },
      {
        name: 'Relatório Saldo Negativo',
        path: '/bo/compliance/negative-funds',
        disabled: isNotAuthorized('BO_COMPLIANCE')
      }
    ]
  },

  {
    name: 'BI SuitPay',
    icon: 'fa-solid fa-chart-line',
    disabled: isNotSomeAuthorization(['BO_FINANCIAL']),
    children: [
      {
        name: 'Relatório de Saldos',
        path: '/bi/balances',
        disabled: isNotAuthorized('BO_FINANCIAL')
      }
    ]
  }
]
